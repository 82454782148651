<template>
    <v-row class="pt-2" :class="{ 'mb-8': extraSpace }">
        <v-col cols="12" sm="4" class="py-0">
            <slot name="col1"></slot>
        </v-col>
        <v-col cols="12" :sm="midSm" class="py-0">
            <slot name="col2"></slot>
        </v-col>
        <v-col cols="12" sm="4" class="py-0">
            <slot name="col3"></slot>
        </v-col>
    </v-row>
</template>

<script>
export default {
    props: {
        extraSpace: Boolean,
    },
    data() {
        return {
            midSm: 4,
        }
    },
    mounted() {
        if (!this.$slots.col1 || !this.$slots.col3) {
            this.midSm = 8
        }
    },
}
</script>
